import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["selected"]
const _hoisted_4 = { class: "select-option-text" }
const _hoisted_5 = ["value", "selected"]
const _hoisted_6 = { class: "select-option-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['c-form-select', _ctx.elementID, { isDisabled: _ctx.isDisabled }]),
    style: _normalizeStyle({ width: _ctx.width })
  }, [
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "c-form-select-label",
          for: _ctx.elementID
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(['select is-primary', { 'is-loading': _ctx.isLoading }])
    }, [
      _createElementVNode("select", {
        class: "py-3 px-4",
        disabled: _ctx.isDisabled,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleSelectOption && _ctx.handleSelectOption(...args)))
      }, [
        (!_ctx.hasDefault)
          ? (_openBlock(), _createElementBlock("option", {
              key: 0,
              value: "null",
              selected: _ctx.modelValue === null || _ctx.modelValue === undefined
            }, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.placeholder), 1)
            ], 8, _hoisted_3))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
          return (_openBlock(), _createElementBlock("option", {
            key: index,
            value: option.value,
            selected: option.value === _ctx.modelValue
          }, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(option.label), 1)
          ], 8, _hoisted_5))
        }), 128))
      ], 40, _hoisted_2)
    ], 2)
  ], 6))
}