
import { defineComponent, onUnmounted, onMounted, watch } from "vue";

const CModal = defineComponent({
  name: "CModal",
  props: {
    show: { type: Boolean, default: true },
  },
  setup(props) {
    function handleHidden() {
      const page = document.querySelector("html");
      if (props.show && page?.style) page.style.overflowY = "hidden";
    }

    function handleRemoveHidden() {
      const page = document.querySelector("html");
      page?.style.removeProperty("overflow-y");
    }

    onMounted(handleHidden);
    onUnmounted(handleRemoveHidden);

    watch(
      () => props.show,
      () => (props.show ? handleHidden() : handleRemoveHidden())
    );
  },
});

export default CModal;
