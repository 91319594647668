import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "modal-card" }
const _hoisted_2 = {
  key: 0,
  class: "modal-card-head"
}
const _hoisted_3 = { class: "modal-card-title" }
const _hoisted_4 = { class: "modal-card-body" }
const _hoisted_5 = {
  key: 1,
  class: "modal-card-foot"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['c-modal modal', { 'is-active': _ctx.show }])
  }, [
    _createElementVNode("div", {
      class: "modal-background",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click:background')))
    }),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.$slots.title)
        ? (_openBlock(), _createElementBlock("header", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, [
              _renderSlot(_ctx.$slots, "title")
            ]),
            _createElementVNode("button", {
              class: "close delete",
              type: "button",
              "aria-label": "close",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click:close')))
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("main", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default"),
        _renderSlot(_ctx.$slots, "content")
      ]),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createElementBlock("footer", _hoisted_5, [
            _renderSlot(_ctx.$slots, "footer")
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}