
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import CModal from "@/components/modal.vue";
import CFormSelect from "@/components/form/select.vue";
import CFormInputText from "@/components/form/input-text.vue";
import PCalendar from "primevue/calendar";
import PProgressBar from "primevue/progressbar";

import { agendaTypeOptions, sexOptions } from "@/utils";
import { PatientForm } from "@/store/patient/types";
import {
  formatCPF,
  formatDate,
  formatDateEn,
  formatPhone,
  formatZipCode,
  removeCPFMask,
  removeFormatZipCode,
  removePhoneMask,
} from "@/utils/formatters";
import { LocalitySearch } from "@/store/locality/types";
import { isEditFormByRouteName } from "@/utils/checkers";

const CPatientForm = defineComponent({
  name: "CPatientForm",
  components: { CModal, PCalendar, CFormSelect, CFormInputText, PProgressBar },
  props: {
    show: { type: Boolean, default: false },
    handleClose: { type: Function, default: () => null },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const _id = Number(route.params._id);
    const isEditForm = isEditFormByRouteName(route.name);
    if (!isEditForm) store.commit("setPatient", null);

    useHead({ title: `${isEditForm ? "Editar" : "Cadastrar"} paciente | obmed` });

    const patient = computed(() => store.state.patient.current);
    const patients = computed(() => store.state.patient.searchList);

    const loading = reactive({ patient: false, submit: false, state: false, city: false, zipCode: false });

    function generateForm(): PatientForm {
      return {
        cd_cidade: patient.value?.cd_cidade?.id || null,
        cd_estado: patient.value?.cd_cidade?.cd_estado.id || null,
        cd_pai: patient.value?.cd_pai || null,
        nm_pessoa_fisica: patient.value?.nm_pessoa_fisica || "",
        nr_cpf: patient.value?.nr_cpf || "",
        dt_nascimento: formatDate(patient.value?.dt_nascimento) || "",
        ie_sexo: patient.value?.ie_sexo || "M",
        nm_bairro: patient.value?.nm_bairro || "",
        nm_rua: patient.value?.nm_rua || "",
        nr_cep: patient.value?.nr_cep || "",
        nr_numero: patient.value?.nr_numero || "",
        ds_email: patient.value?.ds_email || "",
        nr_telefone: patient.value?.nr_telefone.substring(3) || "",
        nm_ref_pessoal: patient.value?.nm_ref_pessoal || "",
        nr_telefone_ref_pessoal: patient.value?.nr_telefone_ref_pessoal?.substring(3) || "",
      };
    }

    const form = ref<PatientForm>(generateForm());
    const zipCode = ref<{ inputText: HTMLInputElement } | null>(null);
    const cep = ref<LocalitySearch | null>();

    const stateOptions = computed(() =>
      store.state.locality.allStates.map((state) => ({ label: state.nm_estado, value: state.id }))
    );
    const cityOptions = computed(() =>
      store.state.locality.allCities.map((city) => ({ label: city.nm_cidade, value: city.id }))
    );

    function autocompleteAddressForm() {
      form.value.nm_rua = cep.value?.logradouro || "";
      form.value.nm_bairro = cep.value?.bairro || "";
    }

    async function handleAddressForm(value: string) {
      console.debug("oi");

      value = formatZipCode(value);

      if (form.value.nr_cep.length < 10 || value !== form.value.nr_cep) {
        form.value.nr_cep = value;

        if (zipCode.value?.inputText) zipCode.value.inputText.value = form.value.nr_cep;
        cep.value = null;

        return;
      }

      loading.zipCode = true;
      const response = await store.dispatch("searchCEP", {
        cep: removeFormatZipCode(form.value.nr_cep),
      });
      cep.value = response?.data || null;
      if (cep.value) autocompleteAddressForm();
      loading.zipCode = false;

      loading.city = true;
      const cityResponse = await store.dispatch("searchCity", { ds_uf: String(response?.data.uf) });
      const selectedCity = cityResponse?.data.find(
        (city) => city.nm_cidade.toLowerCase() === response?.data.localidade.toLowerCase()
      );
      loading.city = false;

      form.value.cd_estado = selectedCity?.cd_estado?.id || null;
      form.value.cd_cidade = selectedCity?.id || null;
    }

    async function getCities() {
      loading.city = true;
      const cd_estado = Number(form.value.cd_estado);
      const ds_uf = store.state.locality.allStates.find((state) => state.id === cd_estado)?.ds_sigla || "";
      await store.dispatch("searchCity", { ds_uf });
      loading.city = false;

      if (!store.state.locality.allCities.find((city) => city.id == form.value.cd_cidade))
        form.value.cd_cidade = store.state.locality.allCities[0]?.id || null;
    }

    async function getStates() {
      loading.state = true;
      await store.dispatch("getStates");
      loading.state = false;

      if (form.value.cd_estado) return;

      const cd_estado = store.state.locality.allStates.find((item) => item.ds_sigla === "PI")?.id || null;
      form.value.cd_estado = cd_estado || (String(cd_estado) as any);
    }

    watch(() => form.value.cd_estado, getCities);
    watch(() => form.value.nr_cep, handleAddressForm);

    async function getPatient() {
      loading.patient = true;
      await store.dispatch("getPatient", { _id });
      loading.patient = false;

      form.value = generateForm();
      if (form.value.nr_cep) handleAddressForm(form.value.nr_cep);
    }

    function handleClose() {
      router.back();
    }

    function validateForm(form: { [key: string]: any }) {
      if (form.value.nr_telefone.length < 10) {
        store.commit("setToast", [{ summary: "Número de telefone inválido!", severity: "error" }]);
        return false;
      }

      return true;
    }

    async function onSubmit() {
      if (!validateForm(form)) return;

      const nr_cep = removeFormatZipCode(form.value.nr_cep);
      const dt_nascimento = formatDateEn(form.value.dt_nascimento.toString());
      const nr_telefone = `+55${removePhoneMask(form.value.nr_telefone)}`;
      const nr_telefone_ref_pessoal = form.value.nr_telefone_ref_pessoal
        ? `+55${removePhoneMask(form.value.nr_telefone_ref_pessoal)}`
        : "";

      loading.submit = true;
      const response = isEditForm
        ? await store.dispatch("updatePatient", {
            _id,
            form: { ...form.value, dt_nascimento, nr_cep, nr_telefone, nr_telefone_ref_pessoal },
          })
        : await store.dispatch("createPatient", {
            form: { ...form.value, dt_nascimento, nr_cep, nr_telefone, nr_telefone_ref_pessoal },
          });
      loading.submit = false;

      if (response?.status === 200 || response?.status === 201) {
        store.dispatch("getPatients");
        handleClose();
      }
    }

    if (isEditForm) getPatient();
    getStates();

    return {
      ...{ form, patient, cep, zipCode, loading, patients },
      ...{ isEditForm, onSubmit, handleClose: props.handleClose || handleClose },
      ...{ agendaTypeOptions, sexOptions, stateOptions, cityOptions },
      ...{ formatCPF, removeCPFMask, formatPhone, removePhoneMask },
    };
  },
});

export default CPatientForm;
